@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
:root {
    --primary: #633194;
    --primary-light: #9c3eba;
    --secondary: #333333;
    --background: #262626;
    --text: #d1d1d1;
    --text-darker: #b8b8b8;
    --page-padding: 1rem;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #262626;
    background-color: var(--background);
    color: #d1d1d1;
    color: var(--text);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body::-webkit-scrollbar {
    width: 0.5em;
}

body::-webkit-scrollbar-corner,
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: #633194;
    background-color: var(--primary);
}

img {
    max-width: calc(100vw - 1rem - 1rem);
    max-width: calc(100vw - var(--page-padding) - var(--page-padding));
    max-height: calc(100vh - 1rem - 1rem);
    max-height: calc(100vh - var(--page-padding) - var(--page-padding));
    /* height: auto; */
    object-fit: cover;
}

.wrapper {
    position: relative;
    padding: 1rem;
    padding: var(--page-padding);
    display: grid;
    justify-content: center;
}

a {
    color: #9c3eba;
    color: var(--primary-light);
    text-decoration: none;
}

a:hover {
    cursor: pointer;
    color: #633194;
    color: var(--primary);
}

